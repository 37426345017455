import { defaultSite, Site } from './Site';
import { Consent, defaultConsent } from "./Consent";
import { OrderStatus } from "./OrderStatus";
import { defaultPatient, Patient } from "./Patient";

export interface Order {
    id: string;
    siteId: string;
    siteName: string;
    customerId: number;
    serialNumber?: string;   
    assetTag?: string;
    trackingNumber?: string;
    currentUserEmail?: string;
    currentUserId?: string;
    statusHistory: OrderStatus[];
    site: Site;
    patient: Patient;
    consent: Consent;
    createdUtc?: Date | null;
    deviceTypeId: number;
}

export const defaultOrder: Order = {
    id: '',
    customerId: 0,  
    siteId: '',  
    siteName: '',
    serialNumber: '',
    trackingNumber: '',
    assetTag: '',
    currentUserId: '',
    currentUserEmail: '',
    statusHistory: [],
    patient: defaultPatient,
    consent: defaultConsent,
    site: defaultSite,
    createdUtc: null,
    deviceTypeId: 0
}