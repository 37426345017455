import Logger from '../helpers/Logger';
import { absoluteUrl } from '../helpers/Routing';
import HttpClient, { BodyType } from '../helpers/HttpClient';
import { Order } from '../models/Order';

export class PatientService {
    private readonly className: string;
    constructor() {
        this.className = 'PatientService';
    }
    async getPriorActiveOrders(customerPatientIdentifier: string): Promise<Order[]> {
        Logger.verbose(`${this.className} - getPriorActiveOrders`);
        let route = `/api/Patient/GetPriorActiveOrders/${customerPatientIdentifier}`;
        const url = absoluteUrl(route);

        const response = await HttpClient.get(url);
        return response.data;
    }
    async anyOtherPatientWithIdentifier(orderId: string, customerPatientIdentifier: string): Promise<boolean> {
        Logger.verbose(`${this.className} - anyOtherPatientWithIdentifier`);
        let route = `/api/Patient/AnyOtherPatientWithIdentifier/${orderId}/${customerPatientIdentifier}`;
        const url = absoluteUrl(route);

        const response = await HttpClient.get(url);
        return response.data;
    }
}
export default new PatientService();