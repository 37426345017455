import moment from 'moment';

class Convert {
    toBoolean(value: any): boolean {
        return (
            value === true
            || value === 'true'
            || value === 'True'
            || value === 1
            || value === '1'
        );
    }

    toFloat(value: any, decimals: number): number {
        let result = Number.parseFloat(value) || 0;
        const decs = this.toIntOrNull(decimals);
        if (decs !== null) {
            result = Number.parseFloat(result.toFixed(decs));
        }
        return result;
    }

    toFloatOrNull(value: any, decimals: number): number | null {
        let result = Number.parseFloat(value);
        const decs = this.toIntOrNull(decimals);
        if (result && decs !== null) {
            result = Number.parseFloat(result.toFixed(decs));
        }
        return result !== 0 && !result ? null : result;
    }

    toInt(value: any): number {
        return Number.parseInt(value, 10) || 0;
    }

    toIntOrNull(value: any): number | null {
        const result = Number.parseInt(value, 10);
        return result !== 0 && !result ? null : result;
    }

    toString(value: any): string {
        return value !== 0 && value !== false && !value ? '' : value.toString();
    }

    toStringOrNull(value: any): string | null {
        return value !== 0 && value !== false && !value ? null : value.toString();
    }

    toCleanGuid(guid: string): string {
        return this.toString(guid)
            .replace(/{/g, '')
            .replace(/-/g, '')
            .replace(/}/g, '');
    }


    humanizeFromNow(utcDate: string, withoutSuffix?: boolean): string {
        return moment.utc(utcDate).fromNow(withoutSuffix);
    }
}

export default new Convert();
