import authService from '../components/api-authorization/AuthorizeService';
import { Order } from '../models/Order';
import { Site } from '../models/Site';
import HttpClient, { BodyType } from '../helpers/HttpClient';
import Logger from '../helpers/Logger';
import { absoluteUrl } from '../helpers/Routing';
import { OrderStatusType } from '../values/Enums';
import { ReportExportData } from '../models/ReportExportData';
import { SiteSummaryReport } from '../models/SiteSummaryReport';

export class OrderService {

	private readonly className: string;

	constructor() {
		this.className = 'OrderService';
	}

	async getSiteSummary(): Promise<SiteSummaryReport[]> {
		Logger.verbose(`${this.className} - siteSummary`);

		let route = `/api/Reports/siteSummary`;

		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		let results = response.data;

		return results;
	}

	async getAll(): Promise<string> {
		Logger.verbose(`${this.className} - getAll`);

		let route = `/api/Reports/Extract?exportFile=true`;

		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		let results = response.data;

		return results;
	}

	async getAllBySite(siteId: string, exportFile:boolean): Promise<ReportExportData[] | string> {
		Logger.verbose(`${this.className} - getAllBySite`);

		let route = `/api/Reports/Extract/${siteId}?exportFile=${exportFile}`;

		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		let results = response.data;

		return results;
	}

}

export default new OrderService();