import React, { ChangeEvent, useEffect, useState } from 'react';
import { User, defaultUser } from '../../models/User';
import { Modal } from 'react-bootstrap';
import { RoleEnum } from '../../values/Enums';
import Loader from '../Shared/Loader';
import { Site } from '../../models/Site';
import { SiteSearchOption } from '../../models/SiteSearchOption';
import SiteSelect from '../Shared/SiteSelect';
import ApprovedSiteRow from './ApprovedSiteRow';
import UserService from '../../services/UserService';

interface PropsData {
    showModal: boolean;
    userId: string | null;
    approvedSites: Site[];
    onClose(success: boolean | null, message: string | null): void;
}

interface StateData {
    user: User;
    siteSearchValue: string;
}

const AddEditUser: React.FC<PropsData> = (props) => {

    const [showLoader, setShowLoader] = useState<boolean>(false);

    const [state, setState] = useState<StateData>({
        user: defaultUser,
        siteSearchValue: '',
    } as StateData);


    useEffect(() => {



        if (props.userId !== null) {
            setShowLoader(true);
            UserService.get(props.userId).then((user: User) => {
                setState({
                    ...state,
                    user: user
                });

                setShowLoader(false);
            });;
        }
        else {
            setState({
                ...state,
                user: defaultUser
            });
        }

    }, [props.userId]);


    const handleEmailTextUpdate = (element: ChangeEvent<HTMLInputElement>) => {
        const value = element.currentTarget.value;
        const updatedUser = { ...state.user, email: value };
        setState({
            ...state,
            user: updatedUser
        });
    };


    const handleFullNameUpdate = (element: ChangeEvent<HTMLInputElement>) => {
        const value = element.currentTarget.value;
        const updatedUser = { ...state.user, fullName: value };
        setState({
            ...state,
            user: updatedUser
        });
    };


    const handleRoleUpdate = (element: ChangeEvent<HTMLSelectElement>) => {
        const value: number = +element.currentTarget.value;
        const updatedUser = { ...state.user, role: value, sites: [] };
        setState({
            ...state,
            user: updatedUser
        });
    };


    const handleClose = () => {
        defaultUser.sites = [];
        setState({
            ...state,
            user: defaultUser,
        });
        props.onClose(null, null);
    };

    const handleInvite = async (): Promise<void> => {
        const email = state.user.email;
        if (state.user.email && state.user.email.includes('@')) {

            setShowLoader(true);
            try {
                const inviteSucceeded = await UserService.invite(state.user);

                defaultUser.sites = [];
                setState({
                    ...state,
                    user: defaultUser,
                });

                if (inviteSucceeded) {
                    props.onClose(true, `Successfully invited ${state.user.email}`);
                }
                else{
                    props.onClose(false, `User '${state.user.email}' already exists'`);
                }
            }
            catch (error) {
                console.error(error);
            }
            finally{
                setShowLoader(false);
            }
        }
    };

    const handleUpdate = async (): Promise<void> => {

        setShowLoader(true);
        const name = state.user.fullName;

        try {
            await UserService.update(state.user);

            defaultUser.sites = [];
            setState({
                ...state,
                user: defaultUser,
            });
            props.onClose(true, `Successfully updated ${name}`);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false);
        }
    };

    const handleAddSite = async (selectedOption: SiteSearchOption) => {

        const selectedOptionArray = (selectedOption instanceof Array) ? selectedOption : [selectedOption];
        const filteredOption = selectedOptionArray[0];

        const selectedSite = state.user.sites?.filter(s => s.siteId === filteredOption.siteId);

        //check to see if the site wasn't already added
        if (selectedSite.length == 0) {

            try {
                const approvedSite = props.approvedSites?.filter(s => s.siteId === filteredOption.siteId)[0];
                const userSites = state.user.sites;
                userSites.push(approvedSite);

                const updatedUser = { ...state.user, sites: userSites };

                setState({
                    ...state,
                    user: updatedUser
                });
            }
            catch (error) {
                console.error(error);
            }
        }
    };

    const handleDeleteSite = async (siteId: string): Promise<void> => {

        const newSiteList = state.user.sites.filter(s => s.siteId != siteId);
        const updatedUser = state.user;
        updatedUser.sites = newSiteList;

        setState({
            ...state,
            user: updatedUser
        });

    };

    return (
        <Modal show={props.showModal} onHide={handleClose} backdrop="static">
            <Loader open={showLoader}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.userId === null ? "Invite" : "Update"} User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label >Name</label>
                        <input type="text" className="form-control" onChange={(evt) => handleFullNameUpdate(evt)} value={state.user.fullName} />
                    </div>
                    <div className="form-group">
                        <label >Email address</label>
                        <input type="email" className="form-control" onChange={(evt) => handleEmailTextUpdate(evt)} value={state.user.email} disabled={props.userId !== null} />
                    </div>
                    <div className="form-group">
                        <label >Role</label>
                        <select className="form-control" onChange={(evt) => handleRoleUpdate(evt)} value={state.user?.role}>
                            <option value={RoleEnum.Site}>Teammate</option>
                            <option value={RoleEnum.CustomerAdmin} >Admin</option>
                        </select>
                    </div>
                    {
                        state.user.role === RoleEnum.Site &&
                        <div className="form-group">
                            <label >Facilities</label>
                            <SiteSelect availableSites={props.approvedSites} siteSelected={handleAddSite} />
                            {state.user.sites && state.user.sites?.length > 0 &&
                                <div className="shadow-sm" style={{ marginTop: 10 }}>
                                    <table className="table table-striped table-bordered table-sm">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">ID</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Address</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.user.sites?.map((site, i) => {
                                                return <ApprovedSiteRow key={`approved-site-${i}`} site={site} deleteSite={handleDeleteSite} />;
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>Cancel</button>
                    {props.userId === null && <button type="button" className="btn btn-primary" onClick={() => handleInvite()}>Invite</button>}
                    {props.userId !== null && <button type="button" className="btn btn-primary" onClick={() => handleUpdate()}>Update</button>}
                </Modal.Footer>
            </Loader>
        </Modal>);
};
export default AddEditUser;