import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Shared/Layout';
import Dashboard from './components/Dashboard/Dashboard';
import Reports from './components/Reports/Reports';
import Admin from './components/Admin/Admin';
import GlobalAuthorize from './components/api-authorization/GlobalAuthorize';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './custom.css';
import { defaultUser, User } from './models/User';
import authService from './components/api-authorization/AuthorizeService';
import UserService from './services/UserService';
import { RoleEnum } from './values/Enums';



export const App: React.FC = () => {

    const [currentUser, setCurrentUser] = useState<User | null>(defaultUser);
    const [forceLogout, setForceLogout] = useState<boolean>(false);

    useEffect(() => {

        authService.getUser().then((user) => {

            if (user && user.sub) {

                UserService.get(user.sub).then( (userData:User) => {
                    setCurrentUser(userData)
                });
            }

        });

    }, []);


    return <Layout currentUser={currentUser} >
        <div className="mt-3"></div>
        <GlobalAuthorize />
        <Route path='/' exact>
            <Dashboard currentUser={currentUser} />
        </Route>
        <Route path='/Dashboard' exact>
            <Dashboard currentUser={currentUser} />
        </Route>
        <Route path='/Admin' exact>
            { (currentUser?.role === RoleEnum.CustomerAdmin || currentUser?.role === RoleEnum.SuperAdmin) &&
                <Admin currentUser={currentUser} />
            }
        </Route>
        <Route path='/Reports' exact>
            {(currentUser?.role === RoleEnum.CustomerAdmin || currentUser?.role === RoleEnum.SuperAdmin) &&
                <Reports currentUser={currentUser}></Reports>
            }            
            </Route>
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
    </Layout>;
};
