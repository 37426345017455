import React, { Fragment } from 'react';
import { Order } from '../../models/Order';
import { User } from '../../models/User';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Confirmation from '../Shared/Confirmation';
import { OrderStatusType } from '../../values/Enums';

interface PropsData {
	currentUser: User | null;
	orders: Order[];
	confirmDeactivate(value: string): void;
	editOrder(value: string): void;
}


const Dashboard: React.FC<PropsData> = (props) => {

	const columns = [
		{
			dataField: 'patient.patientId',
			text: '',
			sort: false,
			formatter: (value: any, row: any) => getEditFormat(value, row),

		},
		{
			dataField: 'site.siteName',
			text: 'Facility',
			sort: true,
			sortFunc: (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => stringSort(a, b, order, dataField, rowA, rowB),
		},
		{
			dataField: 'patient.customerPatientIdentifier',
			text: 'Patient ID',
			sort: true,
			sortFunc: (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => stringSort(a, b, order, dataField, rowA, rowB),
		}, {
			dataField: 'patient.firstName',
			text: 'First Name',
			sort: true,
			sortFunc: (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => stringSort(a, b, order, dataField, rowA, rowB),

		}, {
			dataField: 'patient.lastName',
			text: 'Last Name',
			sort: true,
			sortFunc: (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => stringSort(a, b, order, dataField, rowA, rowB),

		}, {
			dataField: 'serialNumber',
			text: 'Identifiers',
			sort: false,
			formatter: (value: any, row: any) => getIdentifiersFormat(value, row),

		}, {
			dataField: 'createdUtc',
			text: 'Enrollment',
			sort: true,
			sortFunc: (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => dateSort(a, b, order, dataField, rowA, rowB),
			formatter: (value: any, row: any) => getLocalizedTimeFormat(value, row),

		},
		{
			dataField: 'statusHistory[0].status',
			text: 'Status',
			sort: true,
			sortFunc: (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => stringSort(a, b, order, dataField, rowA, rowB),
			formatter: (value: any, row: any) => getStatusFormat(value, row)
		},
		{
			dataField: 'statusHistory[0].statusSetUtc',
			text: 'Status Date',
			sort: true,
			sortFunc: (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => dateSort(a, b, order, dataField, rowA, rowB),
			formatter: (value: any, row: any) => getLocalizedTimeFormat(value, row),

		},
		{
			dataField: '',
			text: '',
			sort: false,
			formatter: (value: any, order: any) => getDeleteFormat(value, order),

		}];

	const dateSort = (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => {
		const dateA = new Date(a).getTime();
		const dateB = new Date(b).getTime();

		if (order === 'asc') {

			return dateB - dateA;
		}
		return dateA - dateB; // desc
	};

	const stringSort = (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => {
		if (order === 'asc' || !order) {
			return b.localeCompare(a, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true });
		}
		return a.localeCompare(b, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true });
	};

	const getLocalizedTimeFormat = (cell: string, row: any) => {

		const date = moment.utc(cell).toDate();
		const localizedDate = moment(date).format('MM/DD/YYYY');

		return (
			<span>{localizedDate}</span>
		);
	};

	const getStatusFormat = (cell: string, order: Order) => {

		const shippedString = OrderStatusType[OrderStatusType.Shipped];
		if (order.statusHistory[0].status == OrderStatusType.Shipped || order.statusHistory[0].status.toString() == shippedString) {
			return <div>
				<div>
					Shipped ({order.statusHistory[0].subStatusText})
				</div>
				<div>
					<i>{order.trackingNumber ?? 'N/A'}</i>
				</div>
			</div>;
		}

		return <span>{cell}</span>;
	};

	const getIdentifiersFormat = (cell: string, order: Order) => {

		if (cell) {
			return <div>
				<div>
					Serial# {order.serialNumber ?? 'N/A'}
				</div>
				<div>
					Asset#: {order.assetTag ?? 'N/A'}
				</div>
			</div>;
		}

		return <label></label>;
	};



	const getEditFormat = (cell: string, row: any) => {
		return (
			<button className="btn" style={{ padding: 0 }} data-patientid={cell} onClick={(e: React.MouseEvent<HTMLButtonElement>) => editOrder(e)}><FontAwesomeIcon size="lg" icon={faEdit} /></button>
		);
	};

	const editOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (event) {
			const value = event.currentTarget.getAttribute('data-patientid');
			const orders = props.orders.filter(o => o.patient.patientId === value);
			if (orders.length > 0) {
				props.editOrder(orders[0].id);
			}
		}
	};

	const getDeleteFormat = (cell: any, order: Order) => {
		const deactivatedValue = OrderStatusType[OrderStatusType.Deactivated];
		if (order.statusHistory[0].status.toString() === deactivatedValue) {
			return <div />;
		}
		else {
			return (
				<button className="btn" data-patientid={order.patient.customerPatientIdentifier} onClick={(e: React.MouseEvent<HTMLButtonElement>) => recallOrder(e)}><FontAwesomeIcon icon={faMinusCircle} /></button>
			);
		}
	};

	const recallOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (event) {
			const value = event.currentTarget.getAttribute('data-patientid');
			const orders = props.orders.filter(o => o.patient.customerPatientIdentifier === value);
			if (orders.length > 0) {
				props.confirmDeactivate(orders[0].id);
			}
		}
	};

	return (
		<Fragment>
			<BootstrapTable
				bootstrap4
				key="id"
				keyField="id"
				bordered={false}
				data={props.orders}
				columns={columns}  //only loading is true, react-bootstrap-table will render overlay
				classes="table table-striped mt-3 shadow-sm"
				headerWrapperClasses="thead-dark"
			// loading={true}  //only loading is true, react-bootstrap-table will render overlay
			// overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
			/>
		</Fragment>

	);
};
export default Dashboard;