import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import GlobalErrorBoundary from './GlobalErrorBoundary';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu currentUser={this.props.currentUser} />
                <div class="wrapper">
                    <Container className="container-fluid">
                        <GlobalErrorBoundary children={this.props.children}>
                        </GlobalErrorBoundary>
                    </Container>
                </div>
            </div>
        );
    }
}

