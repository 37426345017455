import { Site } from "./Site";
import {RoleEnum} from "../values/Enums";

export interface User {
    id?: string | null;
    email: string;
    fullName: string;
    role: RoleEnum;
    sites: Site[];
}


export const defaultUser: User = {
    id: null,
    email: '',
    fullName: '',
    role: RoleEnum.Site,
    sites: []
}