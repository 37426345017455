import React from 'react';
import { Site } from '../../models/Site';
import AsyncSelect from 'react-select/async';
import { SiteSearchOption } from '../../models/SiteSearchOption';

interface SiteSelectProps {
	availableSites: Site[];
	siteSelected(selectedOption: SiteSearchOption): void;
	selectedSite?: Site | null;	
	disabled?: boolean | null;
	clearable?: boolean | null;
}

const SiteSelect: React.FC<SiteSelectProps> = (props) => {
	//set default query terms

	const promiseOptions = async (inputValue: string): Promise<Site[]> => {

		// setTimeout(() => {

		let filteredSites: Site[] = [];
		if (inputValue) {
			filteredSites = props.availableSites.filter(s => s.name.toLowerCase().includes(inputValue.toLowerCase()));
		}
		else {
			filteredSites = props.availableSites;
		}

		return filteredSites;
		// }, 100);
	};

	const onChange = (selectedOption: SiteSearchOption) => {
		props.siteSelected(selectedOption);
	};


	return (
		<AsyncSelect
			isDisabled={props.disabled === true}
			isClearable={props.clearable === true}
			loadOptions={promiseOptions}
			defaultOptions={props.availableSites}
			getOptionLabel={(option) => option.name}
			getOptionValue={(option) => option.siteId}
			// onInputChange={(input) => ();}
			onChange={(value: any) => onChange(value)}					
			controlShouldRenderValue={true}
			value={props.selectedSite}
		/>
	);
};
export default SiteSelect;