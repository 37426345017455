import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
	children: ReactNode;
}

interface State {
	hasError: boolean;
}

class GlobalErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false
	};

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error("Error:", error, errorInfo);
	}

	public render() {
		if (this.state.hasError) {
			return <div className="alert alert-danger" style={{marginTop: 10}} role="alert">
				<h1>Error</h1>
				<div>Please contact support if the issue persists.</div>
			</div>;
		}

		return this.props.children;
	}
}

export default GlobalErrorBoundary;
