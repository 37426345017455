import React, { ChangeEvent, useEffect, useState } from 'react';
import UserRow from './UserRow';
import { User, defaultUser } from '../../models/User';
import userService from '../../services/UserService';
import { RoleEnum } from '../../values/Enums';
import ApprovedSiteService from '../../services/ApprovedSiteService';
import { Site } from '../../models/Site';
import AddEditUser from './AddEditUser';
import UserService from '../../services/UserService';
import Loader from '../Shared/Loader';
import { UploadRequest } from '../../models/UploadRequest';
import { Button, Modal } from 'react-bootstrap';

interface StateData {
    users: User[];
    editUserId: string | null;
    approvedSites: Site[];
    showUserDetails: boolean;
    showErrorDiv: boolean;
    showSuccessDiv: boolean;
    errorMessage: string;
    successMessage: string;
}


const UsersControl: React.FC = () => {
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [confirmationDialogTarget, setConfirmationDialogTarget] = useState<string | null>(null);

    const [state, setState] = useState<StateData>({
        users: [],
        editUserId: null,
        newUser: defaultUser,
        approvedSites: [],
        showUserDetails: false,
        showErrorDiv: false,
        showSuccessDiv: false,
        errorMessage: '',
        successMessage: ''
    } as StateData);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {



        setShowLoader(true);
        userService.getAll().then((userList: User[]) => {

            ApprovedSiteService.getAllApprovedSites(true).then((sites: Site[]) => {

                //we want to hide the admin login
                const usersWithoutAdmin = userList.filter(u => u.role !== RoleEnum.SuperAdmin);

                setState({
                    ...state,
                    approvedSites: sites,
                    users: usersWithoutAdmin
                });

                setShowLoader(false);
            });
        });


    }, []);


    const handleDisableUser = async (): Promise<void> => {
        setShowLoader(true);
        try {
            if (confirmationDialogTarget !== null) {
                const userId = confirmationDialogTarget ?? '';

                //delete site from approvedsite service
                await userService.disable(userId);

                //remove site from approvedSites state
                const newUserList = state.users.filter(user => user.id !== userId);
                setState({
                    ...state,
                    users: newUserList,
                    showErrorDiv: false,
                    showSuccessDiv: false
                });
            }
        }
        catch {
            console.error("Error deleting user");
        }
        finally {
            setConfirmationDialogTarget(null);
            setShowLoader(false);
        }
    };

    const handleModalClose = async (success: boolean | null, message: string | null): Promise<void> => {

        if (success) {
            setShowLoader(true);
            const userList = await userService.getAll();
            const usersWithoutAdmin = userList.filter(u => u.role !== RoleEnum.SuperAdmin);

            setState({
                ...state,
                users: usersWithoutAdmin,
                editUserId: null,
                showUserDetails: false,
                showErrorDiv: false,
                showSuccessDiv: true,
                errorMessage: '',
                successMessage: message ?? "Success"
            });

            setShowLoader(false);
        }
        else {

            setState({
                ...state,
                editUserId: null,
                showUserDetails: false,
                showErrorDiv: !!message,
                showSuccessDiv: false,
                errorMessage: message ?? "Error",
                successMessage: ''
            });
        }

    };

    const handleEditUser = async (userId: string): Promise<void> => {
        setState({
            ...state,
            editUserId: userId,
            showUserDetails: true,
            showErrorDiv: false,
            showSuccessDiv: false
        });
    };

    const handleInviteShow = () => setState({
        ...state,
        showUserDetails: true
    });

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
        setShowLoader(true);

        const fileList = e.target.files;
        if (!fileList) {
            setShowLoader(false);
            return;
        }

        const uploadedFile = fileList[0];
        setSelectedFile(uploadedFile);

        try {

            if (uploadedFile) {
                const uploadRequest: UploadRequest = {
                    file: uploadedFile
                };

                await UserService.inviteUpload(uploadRequest);
                const updatedUserList = await UserService.getAll();

                setState({
                    ...state,
                    users: updatedUserList,
                    showErrorDiv: false,
                    showSuccessDiv: true,
                    errorMessage: '',
                    successMessage: 'File upload was successful'
                });
            }

        }
        catch (error) {

            const errorResponse:any = error as any;
            const msg = errorResponse.message ?? "";


            setState({
                ...state,
                showErrorDiv: true,
                showSuccessDiv: false,
                errorMessage: msg,
                successMessage: ''
            });
        }
        finally {
            setShowLoader(false);
        }
    };

    const handleResendInvite = async (userId: string, userEmail: string): Promise<void> => {
        setShowLoader(true);
        const resendSucceeded = await UserService.resendInvite(userId);
        if (resendSucceeded) {
            handleModalClose(true, `Successfully invited ${userEmail}`);
        }
        else {
            handleModalClose(false, `Failed to sent invite`);
        }
        setShowLoader(false);
    }



    return <div>
        <Loader open={showLoader}>
            <div className="margin-bottom-small m-2">
                <form>

                    <div className="row">
                        <div className="col-sm">
                            <button type="button" className="btn btn-primary font-weight-bold" onClick={handleInviteShow}>Invite</button>
                        </div>
                        <div className="col-sm">

                        </div>
                        <div className="col-sm align-self-end">
                            <div className="custom-file" >
                                <input type="file" className="custom-file-input" id="customFile" onChange={handleFileChange} />
                                <label className="custom-file-label">Upload user csv file</label>
                            </div>
                        </div>
                    </div>


                    {state.showErrorDiv &&
                        <div className="alert alert-danger" role="alert" style={{ marginTop: 10 }}>{state.errorMessage}</div>
                    }
                    {state.showSuccessDiv &&
                        <div className="alert alert-success" role="alert" style={{ marginTop: 10 }}>{state.successMessage}</div>
                    }
                </form>
            </div>

            <div className="shadow-sm">
                <table className="table table-striped table-bordered table-sm dataTableTarget">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.users.map((user, i) => {
                            return <UserRow key={`UserRow-${user.id}`} user={user} editUser={handleEditUser} disableUser={(userId) => setConfirmationDialogTarget(userId)} resendEvent={handleResendInvite} />;
                        })}
                    </tbody>
                </table>
            </div>

            <AddEditUser showModal={state.showUserDetails} userId={state.editUserId} onClose={handleModalClose} approvedSites={state.approvedSites}></AddEditUser>
        </Loader>
        <Modal
            show={confirmationDialogTarget !== null}
            onHide={() => setConfirmationDialogTarget(null)}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure that you want to remove the current user?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setConfirmationDialogTarget(null)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleDisableUser()}>
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    </div >;
};
export default UsersControl;