import React from 'react';
import AsyncSelect from 'react-select/async';
import Convert from '../../helpers/Convert';

interface DeviceSelectProps {
    selectedDeviceId: number;
    disabled?: boolean | null;
    deviceSelected(selectedDeviceId: any): void;
}

const options = [
    { deviceId: 1, name: 'iPhone'},
    { deviceId: 2, name: 'iPad'}
];

const DeviceSelect: React.FC<DeviceSelectProps> = (props) => {

    const onChange = (option: any) => {
        props.deviceSelected(option.deviceId);
    };

    return (
        <AsyncSelect
            isDisabled={props.disabled === true}
            defaultOptions={options}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => Convert.toString(option.deviceId)}
            onChange={(value: any) => onChange(value)}					
            controlShouldRenderValue={true}
            value={options[props.selectedDeviceId-1]}
        />
    );
};
export default DeviceSelect;