import authService from '../components/api-authorization/AuthorizeService';
import { Site } from '../models/Site';
import HttpClient, { BodyType } from '../helpers/HttpClient';
import Logger from '../helpers/Logger';
import { absoluteUrl } from '../helpers/Routing';

export class MaestroSiteService {

	private readonly className: string;

	constructor() {
		this.className = 'MaestroSiteService';
	}

	async getAllSites(): Promise<Site[]> {
		Logger.verbose(`${this.className} - getAllSites`);

		const route = '/api/MaestroSite/';
		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		const results = response.data;

		return results;
	}

	async getSite(siteId:string): Promise<Site> {
		Logger.verbose(`${this.className} - getSite`);

		const route = `/api/MaestroSite/${siteId}`;
		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		const results = response.data;

		return results;
	}
}

export default new MaestroSiteService();