export enum AdminTabs{
	User,
	ApprovedSite
}

export enum RoleEnum {
	Site = 0,
	CustomerAdmin = 1,
	SuperAdmin = 99
}

export enum OrderStatusType{
	Ordered = 0,
	Shipped = 100,
	Active = 200,
	Deactivated = 300
}


export enum SortableDashboardColumns {
	PatientId,
	FirstName,
	LastName,
	SerialNumber,
	Enrollment,
	Status,
	StatusDate
}