import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../Shared/Loader';
import SiteSelect from '../Shared/SiteSelect';
import { Site } from '../../models/Site';
import { SiteSearchOption } from '../../models/SiteSearchOption';
import { User } from '../../models/User';
import ReportService from '../../services/ReportService';
import { ReportExportData } from '../../models/ReportExportData';
import FileSaver from 'file-saver';
import { SiteSummaryReport } from '../../models/SiteSummaryReport';

interface PropsData {
    currentUser: User | null;
}

interface StateData {
    showErrorDiv: boolean;
    showSuccessDiv: boolean;
    errorMessage: string;
    successMessage: string;
}

const Reports: React.FC<PropsData> = (props) => {

    const [exportData, setExportData] = useState<ReportExportData[]>([]);
    const [siteSummaryReport, setSiteSummaryReport] = useState<SiteSummaryReport[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [selectedSite, setSelectedSite] = useState<Site | null>(null);

    const [state, setState] = useState<StateData>({
        showErrorDiv: false,
        showSuccessDiv: false,
        errorMessage: '',
        successMessage: ''
    } as StateData);

    const handleSiteUpdate = async (selectedOption: SiteSearchOption) => {
        setShowLoader(true);

        //get the selected site
        const selectedOptionArray = (selectedOption instanceof Array) ? selectedOption : [selectedOption];
        const filteredOption = selectedOptionArray[0];
        const selectedSiteEntry: Site | undefined = props.currentUser?.sites?.filter(s => s.siteId === filteredOption.siteId)[0] ?? undefined;

        setSelectedSite(selectedSiteEntry ?? null);
        setShowLoader(false);
    };

    const exportSiteData = async () => {
        setShowLoader(true);

        if (selectedSite) {
            //download file
            const allSiteData: string = await ReportService.getAllBySite(selectedSite?.siteId!, true) as string;
            const csvData = new Blob([allSiteData], { type: 'text/csv;charset=utf-8;' });
            FileSaver.saveAs(csvData, `${selectedSite.name.replace(' ', '')}.csv`);
        }

        setShowLoader(false);
    };

    const exportAllData = async () => {
        setShowLoader(true);

        //download file
        const allData: string = await ReportService.getAll() as string;
        const csvData = new Blob([allData], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(csvData, "ExportAll.csv");

        setShowLoader(false);
    };

    
    useEffect(() => {
        setShowLoader(true);

        ReportService.getSiteSummary().then((data: SiteSummaryReport[]) => { 
            setSiteSummaryReport(data);
        })
        .finally( () =>{
            setShowLoader(false);
        } );
    }, []);


    return (
        <div>

            <Loader open={(showLoader)}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            Facility
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: 1, marginRight: 10 }} >
                                    <SiteSelect availableSites={props.currentUser?.sites ?? []} siteSelected={handleSiteUpdate} selectedSite={selectedSite} /></div>
                                <div style={{ flex: 1 }} >
                                    <button type="button" className="btn btn-primary font-weight-bold" onClick={async () => await exportSiteData()}>Export Facility</button></div>
                            </div>
                        </div>
                        <div className="col-sm">

                        </div>
                        <div className="col-sm text-right align-self-end">
                            <button type="button" className="btn btn-primary font-weight-bold" onClick={async () => await exportAllData()}>Export All Facilities</button>
                        </div>
                    </div>
                </div>
                <hr />
                <table className="table table-striped mt-3 shadow-sm">
                    <thead className="thead-dark">
                        <tr>
                            <th>Facility</th>
                            <th>Name</th>
                            <th>Users</th>
                            <th>Orders (non-deactivated)</th>
                        </tr>
                    </thead>

                    <tbody>
                        {siteSummaryReport.map((value: SiteSummaryReport, i) => {
                            return <Fragment key={`row-${i}`}>
                                <tr>
                                    <td>{value.customerSiteId}</td>
                                    <td>{value.siteName}</td>
                                    <td>{value.userCount}</td>
                                    <td>{value.orderCount}</td>
                                </tr>
                            </Fragment>;
                        })}

                    </tbody>

                </table>

            </Loader>
        </div>
    );
};
export default Reports;