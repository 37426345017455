import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../Shared/Loader';
import { Site } from '../../models/Site';
import SiteSelect from '../Shared/SiteSelect';
import { defaultOrder, Order } from '../../models/Order';
import OrderService from '../../services/OrderService';
import MaestroSiteService from '../../services/MaestroSiteService';
import * as _ from 'lodash';
import { User } from '../../models/User';
import { OrderStatusType } from '../../values/Enums';
import PatientService from '../../services/PatientService';
import DeviceSelect from '../Shared/DeviceSelect';

interface PropsData {
    currentUser: User | null;
    showModal: boolean;
    orderId: string | null;
    orderStatus: OrderStatusType;
    userSites: Site[],
    onClose(success: boolean | null, message: string | null): void;
}


interface StateData {
    formValid: boolean;
    showSuccessDiv: boolean;
    showErrorDiv: boolean;
    successMessage: string;
    errorMessages: string[];
}

const AddEditOrder: React.FC<PropsData> = (props) => {

    const [showSiteLoader, setShowSiteLoader] = useState<boolean>(false);
    const [showSaveLoader, setShowSaveLoader] = useState<boolean>(false);
    const [hasConfirmed, setHasConfirmed] = useState<boolean>(false);
    const [currentOrder, setOrder] = useState<Order>(defaultOrder);
    const [dateString, setDateString] = useState<string>('');
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isShipped, setIsShipped] = useState<boolean>(false);
    const [duplicateOrderConfirmationDialogTarget, setDuplicateOrderConfirmationDialogTarget] = useState<boolean>(false);

    const [state, setState] = useState<StateData>({
        formValid: true,
        showSuccessDiv: false,
        showErrorDiv: false,
        successMessage: '',
        errorMessages: []
    } as StateData);

    useEffect(() => {
        setIsEdit(props.orderId !== null);

        if (props.orderId !== null) {
            setShowSaveLoader(true);

            OrderService.get(props.orderId).then((orderResponse: Order) => {
                MaestroSiteService.getSite(orderResponse.site.siteId).then((site: Site) => {

                    orderResponse.site = site;
                    setOrder(orderResponse);

                    var signedDate = new Date(orderResponse.consent.signedUtc!);
                    var formattedDate = getDateString(signedDate);
                    setDateString(formattedDate);


                    setHasConfirmed(true);
                    setShowSaveLoader(false);
                });
            });
        }
        else {
            var today = new Date();
            var formattedDate = getDateString(today);
            setDateString(formattedDate);
            setHasConfirmed(false);
        }

        const orderedString = OrderStatusType[OrderStatusType.Ordered];
        setIsShipped(props.orderId !== null && props.orderStatus.toString() !== orderedString);

    }, [props.orderId]);

    const getDateString = (date: Date) => {
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        return `${mm}/${dd}/${yyyy}`;
    };

    const handleOrderChange = (keyValue: any): void => {
        setOrder({ ...currentOrder, ...keyValue });
    };

    const handleSiteUpdate = async (selectedOption: any) => {

        const selectedOptionArray = (selectedOption instanceof Array) ? selectedOption : [selectedOption];
        const filteredOption = selectedOptionArray[0];

        const selectedSites = props.userSites?.filter(s => s.siteId === filteredOption.siteId);

        //change the target site
        if (selectedSites.length > 0) {
            setShowSiteLoader(true);
            const selectedSite = selectedSites[0];
            const maestroSite = await MaestroSiteService.getSite(selectedSite.siteId);
            handleOrderChange({ site: maestroSite });
            setShowSiteLoader(false);
        }
    };

    const handleDeviceSelected = (selectedDeviceId: number) => {
        setOrder({ ...currentOrder, deviceTypeId: selectedDeviceId });
    };

    const handleClose = () => {
        props.onClose(null, null);
        setOrder(defaultOrder);
        setState({
            ...state,
            showErrorDiv: false,
            showSuccessDiv: false,
            errorMessages: [],
            successMessage: ''
        });
    };

    const handleSubmit = async (): Promise<void> => {
        setShowSaveLoader(true);
        try {
            //check that all fields are validated, if not then set the error message
            const isValidInput = validateInput(true);
            if (isValidInput) {
                const activeOrders = await priorActiveOrders();
                if (!activeOrders) {
                    createOrder();
                } else {
                    setDuplicateOrderConfirmationDialogTarget(true);
                }
            }
        }
        catch (error) {
            console.error(error);
        }
        setShowSaveLoader(false);
    };

    const handleUpdate = async (): Promise<void> => {
        setShowSaveLoader(true);

        try {
            //check that all fields are validated, if not then set the error message
            const isValidInput = await validateInput(false);

            if (isValidInput) {
                if (await anyOtherPatientWithIdentifier()) {
                    var duplicatePatientIdError = "The Patient ID " + currentOrder.patient.customerPatientIdentifier + " belongs to another Davita patient!";
                    setState({
                        ...state,
                        formValid: false,
                        showErrorDiv: true,
                        showSuccessDiv: false,
                        errorMessages: [duplicatePatientIdError],
                        successMessage: '',
                    });
                }
                else {
                    await OrderService.update(currentOrder);
                    props.onClose(true, 'Order successfully updated');
                    setState({
                        ...state,
                        showErrorDiv: false,
                        showSuccessDiv: true,
                        errorMessages: [],
                        successMessage: '',
                    });
                }
            }
        }
        catch (error) {
            const errorCast = error as any;
            setState({
                ...state,
                formValid: false,
                showErrorDiv: true,
                showSuccessDiv: false,
                errorMessages: [errorCast.data],
                successMessage: '',
            });

            console.error(error);
        }

        setShowSaveLoader(false);
    };

    const validateInput = (isNew: boolean): boolean => {
        const errorMessages: string[] = [];

        if (!currentOrder.site?.siteId) {
            errorMessages.push("Select shipping facility");
        }
        if (!currentOrder.patient.firstName?.trim()) {
            errorMessages.push("Missing Patient First Name");
        }
        if (!currentOrder.patient.lastName?.trim()) {
            errorMessages.push("Missing Patient Last Name");
        }
        if (!currentOrder.patient.customerPatientIdentifier?.trim()) {
            errorMessages.push("Missing Patient ID");
        }
        if (currentOrder.deviceTypeId === 0) {
            errorMessages.push("Select Device");

        }
        if (!currentOrder.patient.phoneNumber?.trim()) {
            errorMessages.push("Missing Phone Number");
        }
        if (!currentOrder.consent.userSignature) {
            errorMessages.push("Missing Teammate Signature");
        }
        if (!currentOrder.consent.patientSignature) {
            errorMessages.push("Missing Patient Signature");
        }
        if (isNew) {
            if (currentOrder.consent.userSignature.toLowerCase() !== props.currentUser?.fullName.toLowerCase()) {
                errorMessages.push(`Teammate signature must match '${props.currentUser?.fullName}'`);
            }
        }
        if (errorMessages.length > 0) {

            setState({
                ...state,
                showErrorDiv: true,
                showSuccessDiv: false,
                errorMessages: errorMessages,
                successMessage: '',
                formValid: false
            });

            return false;
        }

        setState({
            ...state,
            showErrorDiv: false,
            showSuccessDiv: false,
            errorMessages: [],
            successMessage: '',
            formValid: true
        });

        return true;
    };

    const priorActiveOrders = async (): Promise<boolean> => {
        const activeOrders = await PatientService.getPriorActiveOrders(currentOrder.patient.customerPatientIdentifier?.trim());
        return activeOrders.length > 0;
    };

    const anyOtherPatientWithIdentifier = async (): Promise<boolean> => {
        return await PatientService.anyOtherPatientWithIdentifier(currentOrder.id, currentOrder.patient.customerPatientIdentifier?.trim());
    }
    const createOrder = async () => {
        try {
            currentOrder.siteId = currentOrder.site?.siteId;
            currentOrder.siteName = currentOrder.site?.name;
            currentOrder.currentUserEmail = props.currentUser?.email;
            currentOrder.currentUserId = props.currentUser?.id ?? '';
            currentOrder.customerId = 0; //fix this next iteration
            await OrderService.create(currentOrder);

            props.onClose(true, 'Order successfully created');

            setState({
                ...state,
                showErrorDiv: false,
                showSuccessDiv: false,
                errorMessages: [],
                successMessage: '',
            });
        } catch (error) {
            const errorCast = error as any;
            setState({
                ...state,
                formValid: false,
                showErrorDiv: true,
                showSuccessDiv: false,
                errorMessages: [errorCast.data],
                successMessage: '',
            });

            console.error(error);
        }
    }
    return (
        <div>
            <Modal show={props.showModal} onHide={handleClose} backdrop="static" dialogClassName="modal-xl">
                <Loader open={showSaveLoader}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isEdit ? "Update" : "Create"} Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {state.showErrorDiv &&
                            <div className="alert alert-danger" role="alert">
                                <ul>
                                    {state.errorMessages.map((error, i) => {
                                        return <li>{error}</li>;
                                    })}
                                </ul>
                            </div>
                        }
                        {state.showSuccessDiv &&
                            <div className="alert alert-success" role="alert">{state.successMessage}</div>
                        }
                        <h5>Patient Details</h5>
                        <form>
                            <div className="form-group row">
                                <div className="form-group col-xs-4 col-sm-4">
                                    <label htmlFor="name" className="control-label">First Name *</label>
                                    <input type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, firstName: evt.target.value } })} value={currentOrder.patient.firstName} />
                                </div>
                                <div className="form-group col-xs-4 col-sm-4">
                                    <label htmlFor="name" className="control-label">Last Name *</label>
                                    <input type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, lastName: evt.target.value } })} value={currentOrder.patient.lastName} />
                                </div>
                                <div className="form-group col-xs-4 col-sm-4">
                                    <label htmlFor="name" className="control-label">Patient ID *</label>
                                    <input disabled={isShipped} type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, customerPatientIdentifier: evt.target.value } })} value={currentOrder.patient.customerPatientIdentifier} />
                                </div>
                            </div>
                            <div className="form-group row mt-2">
                                <div className="form-group col-xs-4 col-sm-4 col-sm-offset-1">
                                    <label htmlFor="name" className="control-label">Address 1</label>
                                    <input type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, address1: evt.target.value } })} value={currentOrder.patient.address1} />
                                </div>
                                <div className="form-group col-xs-4 col-sm-4">
                                    <label htmlFor="name" className="control-label">Phone Number *</label>
                                    <input type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, phoneNumber: evt.target.value } })} value={currentOrder.patient.phoneNumber} />
                                </div>
                                <div className="form-group col-xs-4 col-sm-4">
                                    <label htmlFor="name" className="control-label">Device *</label>
                                    <DeviceSelect disabled={isEdit} deviceSelected={handleDeviceSelected}  selectedDeviceId={currentOrder.deviceTypeId} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="form-group col-xs-4 col-sm-4 col-sm-offset-1">
                                    <label htmlFor="name" className="control-label">Address 2</label>
                                    <input type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, address2: evt.target.value } })} value={currentOrder.patient.address2} />
                                </div>
                                <div className="form-group col-xs-4 col-sm-4">
                                    <label htmlFor="name" className="control-label">Email</label>
                                    <input type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, email: evt.target.value } })} value={currentOrder.patient?.email ?? ''} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="form-group col-xs-4 col-sm-4">
                                    <label htmlFor="name" className="control-label">City</label>
                                    <input type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, city: evt.target.value } })} value={currentOrder.patient.city} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="form-group col-xs-2 col-sm-2">
                                    <label htmlFor="name" className="control-label">State</label>
                                    <input type="text" maxLength={2} className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, state: evt.target.value } })} value={currentOrder.patient.state} />
                                </div>
                                <div className="form-group col-xs-2 col-sm-2">
                                    <label htmlFor="name" className="control-label">ZipCode</label>
                                    <input type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, zipCode: evt.target.value } })} value={currentOrder.patient.zipCode} />
                                </div>
                                <div className="form-group col-xs-4 col-sm-4">
                                    {/* <label htmlFor="name" className="control-label">Phone Number *</label>
                                <input type="text" className="form-control" onChange={(evt) => handleOrderChange({ patient: { ...currentOrder.patient, phoneNumber: evt.target.value } })} value={currentOrder.patient.phoneNumber} /> */}
                                </div>
                                <div className="form-group col-xs-4 col-sm-4">

                                </div>
                            </div>
                            <hr />
                            <div className="form-group row">
                                <div className="form-group col-xs-4 col-sm-4" style={{ zIndex: 2 }}>
                                    <label htmlFor="name" className="control-label">Ship To *:</label>
                                    <SiteSelect disabled={isEdit} availableSites={props.userSites} siteSelected={handleSiteUpdate} selectedSite={currentOrder.site} />
                                    <Loader open={showSiteLoader}>
                                        {
                                            currentOrder.site?.siteId &&
                                            <Fragment>
                                                <div>{currentOrder.site.address1}</div>
                                                <div>{currentOrder.site.address2}</div>
                                                <div>{currentOrder.site.city}, {currentOrder.site.state} {currentOrder.site.zipCode}</div>
                                                <div>Atten: {currentOrder.patient.firstName} {currentOrder.patient.lastName}</div>
                                            </Fragment>
                                        }
                                    </Loader>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <iframe className="overflow-auto" src="/files/Consent.pdf" style={{ height: 200, width: "100%", borderWidth: 1 }}>

                                    </iframe>
                                </div>
                            </div>
                            <div className="">
                                <div className="custom-control custom-checkbox checkbox-lg">
                                    <input className="custom-control-input" disabled={isEdit} type='checkbox' id='confirmationCheck' checked={hasConfirmed} onChange={(evt) => { setHasConfirmed(evt.target.checked); }}></input>
                                    <label className="custom-control-label" htmlFor="confirmationCheck">
                                        <div>By checking the box, you (the teammate) agree:</div>
                                        <ul>
                                            <li>You have confirmed with the patient that they do not have a personal smartphone that they can use to access DCC and that the patient would like to participate in this Program by receiving a DaVita owned mobile device for the utilization of DCC features including telehealth and HTRs.</li>
                                            <li>
                                                Prior to providing the mobile device to the patient, you or another member of the care team will:
                                                <ul>
                                                    <li>Obtain a signed Patient Passport to DaVita Care Connect Patient Consent Form, and</li>
                                                    <li>Train the patient on use of the DCC application per current processes.</li>
                                                </ul>
                                            </li>
                                            <li>You have provided the patient the opportunity to ask questions about the Program.</li>
                                        </ul>
                                    </label>
                                    {/* <label className="custom-control-label" htmlFor="confirmationCheck"> By checking the box you (the patient) are agreeing that you (the patient) do(es) not have a personal smartphone that you (the patient) can use to access DCC and would like to participate in this pilot by receiving a DaVita owned mobile device for the utilization of all the features of DCC including telehealth and HTRs during your treatment with DaVita.</label> */}
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="form-group col-xs-6 col-sm-6 col-sm-offset-1">
                                    <label htmlFor="name" className="control-label">First and Last Name of Patient or Legal Representative</label>
                                    <input type="text" className="form-control" disabled={isEdit} onChange={(evt) => handleOrderChange({ consent: { ...currentOrder.consent, patientSignature: evt.target.value } })} value={currentOrder.consent.patientSignature} />
                                </div>
                                <div className="form-group col-xs-4 col-sm-4">
                                    <label htmlFor="name" className="control-label">Facility</label>
                                    <input type="text" disabled className="form-control" value={currentOrder.site.name} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="form-group col-xs-6 col-sm-6 col-sm-offset-1">
                                    <label htmlFor="name" className="control-label">First and Last Name of DaVita Teammate</label>
                                    <input type="text" className="form-control" disabled={isEdit} onChange={(evt) => handleOrderChange({ consent: { ...currentOrder.consent, userSignature: evt.target.value } })} value={currentOrder.consent.userSignature} />
                                </div>
                                <div className="form-group col-xs-4 col-sm-4">
                                    <label htmlFor="name" className="control-label">Date</label>
                                    <input type="text" disabled className="form-control" value={dateString} />
                                </div>
                            </div>
                        </form>
                        {state.showErrorDiv &&
                            <div className="alert alert-danger" role="alert">
                                <ul>
                                    {state.errorMessages.map((error, i) => {
                                        return <li key={`error-${i}`}>{error}</li>;
                                    })}
                                </ul>
                            </div>
                        }
                        {state.showSuccessDiv &&
                            <div className="alert alert-success" role="alert">{state.successMessage}</div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" disabled={showSaveLoader} className="btn btn-secondary" onClick={() => handleClose()}>Cancel</button>
                        {props.orderId === null && <button type="button" disabled={!hasConfirmed || showSaveLoader} className="btn btn-primary" onClick={() => handleSubmit()}>Submit</button>}
                        {props.orderId !== null && <button type="button" disabled={!hasConfirmed || showSaveLoader} className="btn btn-primary" onClick={() => handleUpdate()}>Update</button>}
                    </Modal.Footer>
                </Loader>
            </Modal>
            <Modal
                show={duplicateOrderConfirmationDialogTarget}
                onHide={() => setDuplicateOrderConfirmationDialogTarget(false)}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Duplicate Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This patient has prior order(s). Would you like to create a new order for the same Patient?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setDuplicateOrderConfirmationDialogTarget(false) }}>
                        No
                    </Button>
                    <Button variant="primary" onClick={(boolean) => { createOrder(); setDuplicateOrderConfirmationDialogTarget(false) }}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default AddEditOrder;
