// polyfills for IE11
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'whatwg-fetch';
// external
import { v1 as uuid } from 'uuid';
// modules
import Logger from './Logger';
import { redirectToRoute, routes, urlIsRoute } from './Routing';
import Timer from './Timer';
import authService from '../components/api-authorization/AuthorizeService';

const AcceptHeaderValue = 'application/json,image/*,audio/*,video/*';
const ContentTypeHeaderValue = 'application/json; charset=utf-8';

const RequestTimeoutMilliseconds = 30000;

interface ResponseModel {
    data: any;
    headers: Headers;
    status: number;
    statusText: string;
    text: string;
}

const className = 'HttpClient';

class HttpClient {
    constructor() {
        this.handleResponse = this.handleResponse.bind(this);
        this.handleResponseError = this.handleResponseError.bind(this);
    }

    public async get(url: string, etag?: string, exportFile?: boolean): Promise<any> {
        const { options, timerId } = await this.createFetchOptions(url, HttpMethods.Get, null, null, etag);

        return new Promise((resolve, reject) => {
            fetch(url, options)
                .then(async (response: any) => this.handleResponse(HttpMethods.Get, response, resolve, reject, timerId, !!exportFile))
                .catch((error: any) => this.handleResponseError(HttpMethods.Get, error, reject, timerId));
        });
    }

    public async post(url: string, data: any, bodyType?: BodyType): Promise<any> {        
        const { options, timerId } = await this.createFetchOptions(url, HttpMethods.Post, data, bodyType);

        return new Promise((resolve, reject) => {
            fetch(url, options)
                .then(async (response: any) => this.handleResponse(HttpMethods.Post, response, resolve, reject, timerId, false))
                .catch((error: any) => this.handleResponseError(HttpMethods.Post, error, reject, timerId));
        });
    }

    public async put(url: string, data: any): Promise<any> {        
        const { options, timerId } = await this.createFetchOptions(url, HttpMethods.Put, data);

        return new Promise((resolve, reject) => {
            fetch(url, options)
                .then(async (response: any) => this.handleResponse(HttpMethods.Put, response, resolve, reject, timerId, false))
                .catch((error: any) => this.handleResponseError(HttpMethods.Put, error, reject, timerId));
        });
    }

    public async patch(url: string, data: any): Promise<any> {
        const { options, timerId } = await this.createFetchOptions(url, HttpMethods.Patch, data);

        return new Promise((resolve, reject) => {
            fetch(url, options)
                .then(async (response: any) => this.handleResponse(HttpMethods.Patch, response, resolve, reject, timerId, false))
                .catch((error: any) => this.handleResponseError(HttpMethods.Patch, error, reject, timerId));
        });
    }

    public async delete(url: string): Promise<any> {
        
        const { options, timerId } = await this.createFetchOptions(url, HttpMethods.Delete);

        return new Promise((resolve, reject) => {
            fetch(url, options)
                .then(async (response: any) => this.handleResponse(HttpMethods.Delete, response, resolve, reject, timerId, false))
                .catch((error: any) => this.handleResponseError(HttpMethods.Delete, error, reject, timerId));
        });
    }

    private async createFetchOptions(url: string, method: string, data?: any, bodyType?: BodyType | null, etag?: string): Promise<any> {
        Logger.verbose(`${className} - createFetchOptions - ${method} : ${url}`);

        const options: RequestInit = { method, cache: 'no-store' };

        const headers: any = { Accept: AcceptHeaderValue };

        if (data) {

            if (bodyType === BodyType.FormData) {
                options.body = this.getFormData(data);
            } else {
                headers['Content-Type'] = ContentTypeHeaderValue;
                options.body = JSON.stringify(data);
            }
        }

        const token = await authService.getAccessToken();
        if(token){
            headers['Authorization'] = `Bearer ${token}`;
        }

        if (etag) {
            headers['E-Tag'] = etag;
            headers['If-None-Match'] = etag;
        }

        // IE11 does it's own caching and must be forced to go to the server ( https://stackoverflow.com/questions/45912500/reactjs-ie11-not-making-new-http-request-using-cached-data )
        headers['Cache-Control'] = 'no-cache';
        // For some reason eslint is freaking out about the Pragma line. The comment below this one fixes it
        // eslint-disable-next-line dot-notation
        headers['Pragma'] = 'no-cache';

        const { signal, timerId } = setRequestTimeout(method, url);

        options.headers = headers;
        options.signal = signal;

        return { options, timerId };
    }

    private getFormData(data: any): FormData {
        const formData = new FormData();
        Object.keys(data).forEach(key => formData.append(key, data[key]));
        return formData;
    }

    private async handleResponse(
        method: string,
        response: Response,
        resolve: (value?: unknown) => void,
        reject: (reason?: any) => void,
        timerId: any,
        exportFile: boolean
    ): Promise<void> {
        Logger.verbose(`${className} - handleResponse - ${method} - ${response.status} : ${response.url}`);

        clearRequestTimeout(timerId);

        const text = await response.text();
        let data: any = null;

        if (text) {
            try {
                data = JSON.parse(text);
            } catch (error) {
                data = text;
            }
        }

        const result: ResponseModel | any = {
            data,
            headers: response.headers,
            status: response.status,
            statusText: response.statusText,
            text
        };

        if (response.ok || result.status === HttpStatusCodes.Status304NotModified) {
            resolve(result);
            return;
        }

        if (result.status === HttpStatusCodes.Status401Unauthorized) {
            this.unauthorized();
        }

        reject(result);
    }

    private handleResponseError(
        method: string,
        error: any,
        reject: (reason?: any) => void,
        timerId: any
    ): void {
        Logger.verbose(`${className} - handleResponseError - ${method}`, error);

        clearRequestTimeout(timerId);
        reject(error);
    }

    private unauthorized(): void {
        // if (!urlIsRoute(routes.auth.login) && !urlIsRoute(routes.auth.logout)) 
        // {
        //     redirectToRoute(routes.auth.logout);
        // }
    }
}

const clearRequestTimeout = (timerId: any): void => {
    Logger.verbose(`${className} - clearRequestTimeout`);
    Timer.clearTimeout(timerId);
};

const setRequestTimeout = (method: string, url: string): any => {
    Logger.verbose(`${className} - setRequestTimeout`);

    const controller = new AbortController();
    const signal = controller.signal;
    const timerId = uuid();

    const abort = (): void => {
        Logger.warn(`${className} - request timed out, aborting`, [method, url]);
        controller.abort();
    };

    Timer.setTimeout(timerId, abort, RequestTimeoutMilliseconds);

    return { signal, timerId };
};

export default new HttpClient();

export enum BodyType {
    Json,
    FormData
}

export const HttpMethods = Object.freeze({
    Get: 'GET',
    Post: 'POST',
    Put: 'PUT',
    Patch: 'PATCH',
    Delete: 'DELETE'
});

export const HttpStatusCodes = Object.freeze({
    Status100Continue: 100,
    Status101SwitchingProtocols: 101,
    Status102Processing: 102,
    Status200OK: 200,
    Status201Created: 201,
    Status202Accepted: 202,
    Status203NonAuthoritative: 203,
    Status204NoContent: 204,
    Status205ResetContent: 205,
    Status206PartialContent: 206,
    Status207MultiStatus: 207,
    Status208AlreadyReported: 208,
    Status226IMUsed: 226,
    Status300MultipleChoices: 300,
    Status301MovedPermanently: 301,
    Status302Found: 302,
    Status303SeeOther: 303,
    Status304NotModified: 304,
    Status305UseProxy: 305,
    Status306SwitchProxy: 306,
    Status307TemporaryRedirect: 307,
    Status308PermanentRedirect: 308,
    Status400BadRequest: 400,
    Status401Unauthorized: 401,
    Status402PaymentRequired: 402,
    Status403Forbidden: 403,
    Status404NotFound: 404,
    Status405MethodNotAllowed: 405,
    Status406NotAcceptable: 406,
    Status407ProxyAuthenticationRequired: 407,
    Status408RequestTimeout: 408,
    Status409Conflict: 409,
    Status410Gone: 410,
    Status411LengthRequired: 411,
    Status412PreconditionFailed: 412,
    Status413RequestEntityTooLarge: 413,
    Status413PayloadTooLarge: 413,
    Status414RequestUriTooLong: 414,
    Status414UriTooLong: 414,
    Status415UnsupportedMediaType: 415,
    Status416RequestedRangeNotSatisfiable: 416,
    Status416RangeNotSatisfiable: 416,
    Status417ExpectationFailed: 417,
    Status418ImATeapot: 418,
    Status419AuthenticationTimeout: 419,
    Status421MisdirectedRequest: 421,
    Status422UnprocessableEntity: 422,
    Status423Locked: 423,
    Status424FailedDependency: 424,
    Status426UpgradeRequired: 426,
    Status428PreconditionRequired: 428,
    Status429TooManyRequests: 429,
    Status431RequestHeaderFieldsTooLarge: 431,
    Status451UnavailableForLegalReasons: 451,
    Status500InternalServerError: 500,
    Status501NotImplemented: 501,
    Status502BadGateway: 502,
    Status503ServiceUnavailable: 503,
    Status504GatewayTimeout: 504,
    Status505HttpVersionNotsupported: 505,
    Status506VariantAlsoNegotiates: 506,
    Status507InsufficientStorage: 507,
    Status508LoopDetected: 508,
    Status510NotExtended: 510,
    Status511NetworkAuthenticationRequired: 511
});
