import React, { Component, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from '../api-authorization/LoginMenu';
import './NavMenu.css';
import authService from '../api-authorization/AuthorizeService'
import { RoleEnum } from '../../values/Enums';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
            collapsed: true,
            authenticated: false,
            dashboardActive: true,
            reportsActive: false,
            adminActive: false
        };
    }

    async componentDidMount() {
        const isAuthenticated = await authService.isAuthenticated();
        this.setState({ authenticated: isAuthenticated });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }



    render() {

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow navbar-dark bg-dark" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><h3>DaVita Device Ordering Portal</h3></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex" style={{ justifyContent: "space-between"}} isOpen={!this.state.collapsed} navbar>
                            {(this.state.authenticated && (this.props.currentUser?.role === RoleEnum.CustomerAdmin || this.props.currentUser?.role === RoleEnum.SuperAdmin)) &&
                                <ul className="navbar-nav">
                                    <NavItem className="nav-pills shadow-sm">
                                        <NavLink tag={Link} className="font-weight-bold" to="/Dashboard" onClick={() => this.setState({ adminActive: false, reportsActive: false, dashboardActive: true })} active={this.state.dashboardActive} >Dashboard</NavLink>
                                    </NavItem>
                                    <NavItem className="nav-pills">
                                        <NavLink tag={Link} className="font-weight-bold" to="/Reports" onClick={() => this.setState({ adminActive: false, reportsActive: true, dashboardActive: false })} active={this.state.reportsActive} >Reports</NavLink>
                                    </NavItem>
                                    <NavItem className="nav-pills">
                                        <NavLink tag={Link} className="font-weight-bold" to="/Admin" onClick={() => this.setState({ adminActive: true, reportsActive: false, dashboardActive: false })} active={this.state.adminActive} >Admin</NavLink>
                                    </NavItem>
                                </ul>
                            }
                            <ul className="navbar-nav flex-grow" style={{ marginLeft: "auto"}}>
                                <LoginMenu currentUser={this.props.currentUser}>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
