import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import { urlIsAuthRoute } from '../../helpers/Routing';

export default class GlobalAuthorize extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.authenticationChanged();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;
        var link = document.createElement("a");
        link.href = '/';
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        //const redirectUrl = `${ApplicationPaths.Login}`; //?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
        const redirectUrl = `/authentication/login`;

        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        return <div></div>
                    }
                    else if (urlIsAuthRoute()) {
                        return <div></div>
                    }

                    else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {


    }

    async authenticationChanged() {
        const authenticated = await authService.isAuthenticated();

        this.setState({ ready: true, authenticated });
    }
}
