import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserService from '../../services/UserService';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

    }



    render() {

        if (!this.props.currentUser?.fullName) {
            return this.emptyView();
        } else {

            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

            return this.authenticatedView(profilePath, logoutPath);
        }
    }

    authenticatedView(profilePath, logoutPath) {
        return (<Fragment>
            <NavItem style={{ display: 'inline-flex', alignItems: 'center' }}>
                <NavLink tag={Link} className="text-white" to={profilePath} >{this.props.currentUser?.fullName}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-white font-weight-bold" to={logoutPath}><FontAwesomeIcon icon={faSignOutAlt} size="2x" /></NavLink>
            </NavItem>
        </Fragment>);

    }

    emptyView() {
        return (<Fragment>
            
        </Fragment>);
    }
}
