import authService from '../components/api-authorization/AuthorizeService';
import { Order } from '../models/Order';
import { Site } from '../models/Site';
import HttpClient, { BodyType } from '../helpers/HttpClient';
import Logger from '../helpers/Logger';
import { absoluteUrl } from '../helpers/Routing';
import { OrderStatusType } from '../values/Enums';

export class OrderService {

	private readonly className: string;

	constructor() {
		this.className = 'OrderService';
	}

	async getAll(showDeactivated: boolean): Promise<Order[]> {
		Logger.verbose(`${this.className} - getAll`);

		let route = `/api/Order/all`;

		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		let results = response.data;

		if (!showDeactivated) {
			const deactivatedValue = OrderStatusType[OrderStatusType.Deactivated];
			results = results.filter((order: Order) => order.statusHistory[0].status.toString() !== deactivatedValue);
		}

		return results;
	}

	async getSite(siteId: string, showDeactivated: boolean): Promise<Order[]> {
		Logger.verbose(`${this.className} - getSite`);

		let route = `/api/Order/site/${siteId}`;

		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		let results = response.data;

		if (!showDeactivated) {
			const deactivatedValue = OrderStatusType[OrderStatusType.Deactivated];
			results = results.filter((order: Order) => order.statusHistory[0].status.toString() !== deactivatedValue);
		}

		return results;
	}

	async get(orderId: string): Promise<Order> {
		Logger.verbose(`${this.className} - get`);

		const route = `/api/Order/${orderId}`;
		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		const results = response.data;

		return results;
	}

	async deactivate(orderId: string): Promise<void> {
		Logger.verbose(`${this.className} - deactivate`);

		const route = `/api/Order/${orderId}`;
		const url = absoluteUrl(route);

		await HttpClient.delete(url);
	}

	async update(order: Order): Promise<void> {
		Logger.verbose(`${this.className} - update`);

		const route = `/api/Order/${order.id}`;
		const url = absoluteUrl(route);

		await HttpClient.put(url, order);
	}


	async create(order: Order): Promise<string> {
		Logger.verbose(`${this.className} - create`);

		const route = `/api/Order/`;
		const url = absoluteUrl(route);

		return await HttpClient.post(url, order, BodyType.Json);
	}

}

export default new OrderService();