import moment from 'moment';

interface Log extends Console {
    [key: string]: any;
}

class Logger {
    private readonly LogLevel: any = Object.freeze({
        VERBOSE: 1,
        DEBUG: 2,
        INFORMATION: 3,
        WARNING: 4,
        ERROR: 5,
        FATAL: 6,
        NONE: 99
    });

    private readonly LogLevelMap: any = Object.freeze({
        CRITICAL: 'FATAL',
        DEBUG: 'DEBUG',
        ERROR: 'ERROR',
        INFORMATION: 'INFORMATION',
        TRACE: 'VERBOSE',
        WARNING: 'WARNING'
    });

    private readonly LogConsole: any = Object.freeze({
        1: 'debug',
        2: 'debug',
        3: 'info',
        4: 'warn',
        5: 'error',
        6: 'error'
    });

    private readonly console: Log;

    public constructor() {
        this.console = window.console;
    }

    public currentLevel(): number {
        //const mappedLevel = this.LogLevelMap[Config.logLevel.toUpperCase()];
        const mappedLevel =  this.LogLevel.VERBOSE;
        const logLevel = this.LogLevel[mappedLevel] || this.LogLevel.NONE;
        return logLevel;
    }

    /* eslint-disable @typescript-eslint/no-empty-function */

    public verbose(message: string, data?: any): void {
        new Promise(resolve => {
            if (this.LogLevel.VERBOSE >= this.currentLevel()) {
                this.log(this.LogLevel.VERBOSE, '[VERBOSE]', message, data);
            }
            //resolve();
        }).finally(() => {});
    }

    public debug(message: string, data?: any): void {
        new Promise(resolve => {
            if (this.LogLevel.DEBUG >= this.currentLevel()) {
                this.log(this.LogLevel.DEBUG, '[DEBUG]', message, data);
            }
            //resolve();
        }).finally(() => {});
    }

    public info(message: string, data?: any): void {
        new Promise(resolve => {
            if (this.LogLevel.INFORMATION >= this.currentLevel()) {
                this.log(this.LogLevel.INFORMATION, '[INFO]', message, data);
            }
            //resolve();
        }).finally(() => {});
    }

    public warn(message: string, data?: any): void {
        new Promise(resolve => {
            if (this.LogLevel.WARNING >= this.currentLevel()) {
                this.log(this.LogLevel.WARNING, '[WARN]', message, data);
            }
            //resolve();
        }).finally(() => {});
    }

    public error(message: string, data?: any): void {
        new Promise(resolve => {
            if (this.LogLevel.ERROR >= this.currentLevel()) {
                this.log(this.LogLevel.ERROR, '[ERROR]', message, data);
            }
            //resolve();
        }).finally(() => {});
    }

    public fatal(message: string, data?: any): void {
        new Promise(resolve => {
            if (this.LogLevel.FATAL >= this.currentLevel()) {
                this.log(this.LogLevel.FATAL, '[FATAL]', message, data);
            }
            //resolve();
        }).finally(() => {});
    }

    /* eslint-enable @typescript-eslint/no-empty-function */

    private log(level: number, label: string, message: string, data: any): void {
        const consoleMessage = `${moment().toISOString()} ${label} - ${message}`;
        const logger: string = this.LogConsole[level] || 'log';

        if (data !== undefined) {
            this.console[logger](consoleMessage, data);
        } else {
            this.console[logger](consoleMessage);
        }

        // if (level >= this.LogLevel.ERROR && level < this.LogLevel.NONE) {
        //     const appInsights = getAppInsights();

        //     if (appInsights) {
        //         appInsights.trackException({ exception: new Error(consoleMessage) });
        //     }
        // }
    }
}

export default new Logger();
