export interface Site {
    siteId: string;
    customerSiteId?: string;
    name: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    assignedUserCount?: number;
    allOrderCount?: number;
    openOrderCount?: number;
}

export const defaultSite: Site = {
    siteId: '',
    customerSiteId: '',
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    assignedUserCount: 0,
    allOrderCount: 0,
    openOrderCount: 0
}