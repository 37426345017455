import React, { Fragment } from 'react';
import { User } from '../../models/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { RoleEnum } from '../../values/Enums';

interface PropsData {
    user: User;
    disableUser(userId: string): void;
    editUser(userId: string): void;
    resendEvent(userId: string, userEmail: string): void;
}

const UserRow: React.FC<PropsData> = (props) => {


    return <Fragment>
        <tr>
            <td className="align-middle"><div style={{ marginLeft: 10 }}>{props.user.fullName}</div></td>
            <td className="align-middle"><div style={{marginLeft: 10}}>{props.user.email}</div></td>
            <td className="align-middle"><div style={{ marginLeft: 10 }}>{props.user.role === RoleEnum.Site ? "Teammate" : "Admin"}</div></td>
            <td className="align-middle text-center"><button id={`btn-${props.user.id}`} className="btn align-middle" onClick={() => props.resendEvent(props.user.id!, props.user.email!)}><FontAwesomeIcon icon={faEnvelope} /></button></td>
            <td className="align-middle text-center"><button id={`btn-${props.user.id}`} className="btn align-middle" onClick={() => props.editUser(props.user.id!)}><FontAwesomeIcon icon={faEdit} /></button></td>
            <td className="align-middle text-center"><button id={`btn-${props.user.id}`} className="btn align-middle" onClick={() => props.disableUser(props.user.id!)}><FontAwesomeIcon icon={faTrash} /></button></td>
        </tr>
    </Fragment>;
}
export default UserRow;
