import { defaultUser } from './../models/User';
import authService from '../components/api-authorization/AuthorizeService';
import { User } from '../models/User';
import HttpClient, { BodyType } from '../helpers/HttpClient';
import Logger from '../helpers/Logger';
import { absoluteUrl } from '../helpers/Routing';
import { RoleEnum } from '../values/Enums';
import { UploadRequest } from '../models/UploadRequest';

export class UserService {


	private readonly className: string;

	constructor() {
		this.className = 'UserService';
	}

	async getAll(): Promise<User[]> {
		Logger.verbose(`${this.className} - getAll`);

		const route = '/api/User/';
		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		const results: User[] = response.data;

		//string to number conversion
		results.forEach((r: any) => {
			switch (r.role) {
				case RoleEnum[RoleEnum.CustomerAdmin]:
					r.role = RoleEnum.CustomerAdmin;
					break;
				case RoleEnum[RoleEnum.Site]:
					r.role = RoleEnum.Site;
					break;
				case RoleEnum[RoleEnum.SuperAdmin]:
					r.role = RoleEnum.SuperAdmin;
					break;
			}
		});

		return results;
	}

	async get(userId: string): Promise<User> {
		Logger.verbose(`${this.className} - get`);

		const route = `/api/User/${userId}`;
		const url = absoluteUrl(route);

		let user;

		try {
			const response = await HttpClient.get(url);
			user = response.data;
		}
		catch (error) {
			console.error(error);
		}

		if (user?.role) {
			//string to enum conversion
			switch (user.role) {
				case RoleEnum[RoleEnum.CustomerAdmin]:
					user.role = RoleEnum.CustomerAdmin;
					break;
				case RoleEnum[RoleEnum.Site]:
					user.role = RoleEnum.Site;
					break;
				case RoleEnum[RoleEnum.SuperAdmin]:
					user.role = RoleEnum.SuperAdmin;
					break;
			}
		}

		return user;
	}

	async getFullName(userId: string): Promise<User> {
		Logger.verbose(`${this.className} - getFullName`);

		const route = `/api/User/${userId}/FullName`;
		const url = absoluteUrl(route);

		const response = await HttpClient.get(url);
		const fullName = response.data;

		return fullName;
	}

	async disable(userId: string): Promise<void> {
		Logger.verbose(`${this.className} - disableUser`);

		const route = `/api/User/${userId}`;
		const url = absoluteUrl(route);

		await HttpClient.delete(url);
	}

	async invite(user: User): Promise<boolean> {
		Logger.verbose(`${this.className} - inviteUser`);

		const route = `/api/User/invite`;
		const url = absoluteUrl(route);

		try {
			await HttpClient.post(url, user, BodyType.Json);
			return true;
		}
		catch (error) {
			console.error(error);
			return false;
		}

	}


	async inviteUpload(request: UploadRequest): Promise<void> {
		Logger.verbose(`${this.className} - inviteUser`);

		const route = `/api/User/upload`;
		const url = absoluteUrl(route);

		await HttpClient.post(url, request, BodyType.FormData);
	}


	async update(user: User): Promise<User> {
		Logger.verbose(`${this.className} - updateUser`);

		const route = `/api/User/${user.id}`;
		const url = absoluteUrl(route);

		try {
			const response = await HttpClient.put(url, user);
			const results = response.data;

			return results;
		}
		catch (error) {
			console.error(error);
		}

		return defaultUser;
	}

	async resendInvite(userId: string): Promise<boolean> {
		Logger.verbose(`${this.className} - resendInvite`);

		const route = `/api/User/resendInvite`;
		const url = absoluteUrl(route);

		try {
			await HttpClient.post(url, userId, BodyType.Json);
			return true;
		}
		catch (error) {
			console.error(error);
			return false;
		}

	}
}

export default new UserService();