import React, { useState } from 'react';
import {AdminTabs} from '../../values/Enums'
import UsersControl from './UsersControl'
import ApprovedSitesControl from './ApprovedSitesControl'
import { User } from '../../models/User';
import GlobalAuthorize from '../api-authorization/GlobalAuthorize';

interface PropsData {
  currentUser: User | null;
}

const Admin: React.FC<PropsData> = (props) => {
  const [selectedTab, setSelectedTab] = useState<AdminTabs>(AdminTabs.User);

  const showTab = (enumValue: AdminTabs) => {
    setSelectedTab(enumValue);
  };
  
  return (
    <div>

      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a className={selectedTab === AdminTabs.User ? "nav-link active font-weight-bold" : "nav-link font-weight-bold"} onClick={() => showTab(AdminTabs.User)}>Users</a>
        </li>
        <li className="nav-item">
          <a className={selectedTab === AdminTabs.ApprovedSite ? "nav-link active font-weight-bold " : "nav-link font-weight-bold "} onClick={() => showTab(AdminTabs.ApprovedSite)}>Approved Facilities</a>
        </li>
      </ul>
      <div className="tab-content">
        {selectedTab === AdminTabs.User &&
          <UsersControl />
        }
        {selectedTab === AdminTabs.ApprovedSite &&
          <ApprovedSitesControl />
        }
      </div>
    </div>
  );
}
export default Admin;
