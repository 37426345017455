import { compile } from 'path-to-regexp';
import Url from 'url-parse';
import Convert from './Convert';
import Logger from './Logger';

const className = 'Routing';
const location = window.location;

export const routes = {
    root: '/',

    auth: {
        root: '/authentication',
        change: '/authentication/change',
        forgot: '/authentication/forgot',
        login: '/authentication/login',
        logout: '/authentication/logout',
        register: '/authentication/register',
        reset: '/authentication/reset'
    },

    user: {
        root: '/users',
        mfa: {
            enable: '/users/mfa/enable',
            disable: '/users/mfa/disable'
        }
    }
};

export function parseQuery(): any {
    const url = new Url(location.href, true);
    const query = url.query;

    return query;
}

export function queryString(url?: string): string {
    const queryUrl: string = url || location.href;

    const path = new Url(queryUrl);

    return Convert.toString(path.query);
}

export function redirectToRoute(route: string, data?: any): void {
    const url = absoluteUrl(route, data);

    Logger.verbose(`${className} - redirectToRoute`, url);

    location.href = url;
}

export function redirectToUrl(url: string): void {
    location.href = url;
}

export function relativeUrl(route: string, data?: any): string {
    const toPath = compile(route);
    const path = toPath(data);

    return path;
}

export function absoluteUrl(route: string, data?: any): string {
    let rUrl = relativeUrl(route, data);
    // if(rUrl && rUrl[0] === '/'){
    //     rUrl = rUrl.substring(1);
    // }
    const aUrl = `${location.protocol}//${location.host}${rUrl}`;

    return aUrl;
}

export function urlIsRoute(route: string, data?: any): boolean {
    const relativeRoute = relativeUrl(route, data);

    const isRoute = relativeRoute === location.pathname;

    return isRoute;
}

export function urlIsAuthRoute(): boolean {

    const iAuthRoute = location.pathname.includes(routes.auth.root);

    return iAuthRoute;
}