import React from 'react';
import { Spinner } from 'react-bootstrap';

interface LoaderProps {
    open: boolean;
    children: React.ReactNode;
    size?: string;
}

const Loader: React.FC<LoaderProps> = (props) => {
    if (props.open) {
        return (
            <div style={{
                position: 'relative',
                minHeight: 200,
                width: '100%',
                overflow: 'hidden'
            }}>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'calc(100% + 10px)',
                    height: 'calc(100% + 4px)',
                    backgroundColor: 'rgba(248, 248, 252, 0.4)',
                    borderRadius: 6,
                    left: -5,
                    top: -2,
                    zIndex: 9999
                }}>
                    <Spinner animation="border" style={{
                        margin: 20
                    }}/>
                </div>
                {props.children}
            </div>
        );
    }
    return (<div>{props.children}</div>);
};
export default Loader;
