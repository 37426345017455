export interface Consent {    
    patientSignature: string;
    userSignature: string;
    userEmail: string;
    signedUtc: Date|null;
}


export const defaultConsent: Consent = {
    patientSignature: '',
    userSignature: '',
    userEmail: '',
    signedUtc: null
}