import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { Site } from '../../models/Site';

interface PropsData {
    site: Site;
    showCounts?: boolean;
    deleteSite(siteId: string): void;
}

const ApprovedSiteRow: React.FC<PropsData> = (props) => {

    let fullAddress = props.site.address1;
    if(props.site.address2){
        fullAddress += ` ${props.site.address2}`
    }
    fullAddress += `, ${props.site.city} ${props.site.state} ${props.site.zipCode}`

    return <Fragment>
        <tr>
            <td>{props.site.customerSiteId}</td>
            <td className="align-middle">{props.site.name}</td>
            <td>{fullAddress}</td>
            {props.showCounts && 
                <Fragment>
                <td>{props.site.assignedUserCount}</td>
                <td>{props.site.openOrderCount}</td>
                </Fragment>
            }
            <td className="align-middle text-center"><button className="btn align-middle" onClick={() => props.deleteSite(props.site.siteId)}><FontAwesomeIcon icon={faTrash} /></button></td>
        </tr>
    </Fragment >;


}
export default ApprovedSiteRow;