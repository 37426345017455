import React, { ChangeEvent, ChangeEventHandler, Component, MouseEventHandler, useEffect, useState } from 'react';
import ApprovedSiteRow from './ApprovedSiteRow';
import { Site, defaultSite } from '../../models/Site';
import ApprovedSiteService from '../../services/ApprovedSiteService';
import MaestroSiteService from '../../services/MaestroSiteService';
import Loader from '../Shared/Loader';
import SiteSelect from '../Shared/SiteSelect';
import { SiteSearchOption } from '../../models/SiteSearchOption';
import { UploadRequest } from '../../models/UploadRequest';
import { Button, Modal } from 'react-bootstrap';


interface StateData {
    selectedSite: Site;
    showSuccessDiv: boolean;
    showErrorDiv: boolean;
    successMessage: string;
    errorMessage: string;
}

const ApprovedSitesControl: React.FC = () => {

    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [approvedSites, setApprovedSites] = useState<Site[]>([]);
    const [maestroSites, setMaestroSites] = useState<Site[]>([]);
    const [addConfirmationDialogTarget, setAddConfirmationDialogTarget] = useState<Site | null>(null);
    const [removeConfirmationDialogTarget, setRemoveConfirmationDialogTarget] = useState<string | null>(null);

    const [state, setState] = useState<StateData>({
        selectedSite: defaultSite,
        showSuccessDiv: false,
        showErrorDiv: false,
        successMessage: '',
        errorMessage: ''
    } as StateData);

    const handleDeleteSite = async (siteId: string | null): Promise<void> => {
        try {
            if (siteId !== null) {
                //delete site from approvedSite service
                await ApprovedSiteService.delete(siteId!);
                const updatedSites = await ApprovedSiteService.getAllApprovedSites();
                //remove site from approvedSites state

                setApprovedSites(updatedSites);
                setAddConfirmationDialogTarget(null);
                setRemoveConfirmationDialogTarget(null);

                setState({
                    ...state,
                    showErrorDiv: false,
                    showSuccessDiv: true,
                    errorMessage: '',
                    successMessage: 'Site removed'
                });
            }
        }
        catch {
            const errorMessage = `Error deleting approved site`;
            setState({
                ...state,
                showErrorDiv: true,
                showSuccessDiv: false,
                errorMessage: errorMessage,
                successMessage: ''
            });
        }
    };


    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
        setShowLoader(true);
        try {

            const fileList = e.target.files;
            if (!fileList) {
                setShowLoader(false);
                return;
            }

            const uploadedFile = fileList[0];
            setSelectedFile(uploadedFile);

            try {

                if (uploadedFile) {
                    const uploadRequest: UploadRequest = {
                        file: uploadedFile
                    };

                    await ApprovedSiteService.upload(uploadRequest);

                    const updatedSites = await ApprovedSiteService.getAllApprovedSites();
                    setApprovedSites(updatedSites);
                }

            }
            catch (error) {
                const errorMessage = `Error getting file data`;
                setState({
                    ...state,
                    showErrorDiv: true,
                    showSuccessDiv: false,
                    errorMessage: errorMessage,
                    successMessage: ''
                });
                setShowLoader(false);
                return;
            }

            setState({
                ...state,
                showErrorDiv: false,
                showSuccessDiv: true,
                errorMessage: '',
                successMessage: 'Uploaded file'
            });
            setShowLoader(false);
        }
        catch {
            const errorMessage = `Error getting file data`;
            setState({
                ...state,
                showErrorDiv: true,
                showSuccessDiv: false,
                errorMessage: errorMessage,
                successMessage: ''
            });
        }
    };


    const handleAddSite = async (selectedOption: SiteSearchOption) => {

        const selectedOptionArray = (selectedOption instanceof Array) ? selectedOption : [selectedOption];
        const filteredOption = selectedOptionArray[0];

        const selectedSite = approvedSites?.filter(s => s.siteId === filteredOption.siteId);

        if (selectedSite.length == 0) {
            const targetSite = maestroSites.filter(s => s.siteId === filteredOption.siteId)[0];
            setAddConfirmationDialogTarget(targetSite);
        }
        else {
            setState({
                ...state,
                showErrorDiv: true,
                showSuccessDiv: false,
                errorMessage: "Facility already exists in list",
                successMessage: ''
            });
        }
    };

    const addApprovedSite = async () => {

        //check to see if the site wasn't already added

        if (addConfirmationDialogTarget)
        {
            try {
                //add to backend datastore
                await ApprovedSiteService.add(addConfirmationDialogTarget.siteId);
                const updatedSites = await ApprovedSiteService.getAllApprovedSites();

                //set state
                setApprovedSites(updatedSites);
                setAddConfirmationDialogTarget(null);

                setState({
                    ...state,
                    showErrorDiv: false,
                    showSuccessDiv: true,
                    errorMessage: '',
                    successMessage: 'Facility added'
                });
            }
            catch (error) {
                setState({
                    ...state,
                    showErrorDiv: true,
                    showSuccessDiv: false,
                    errorMessage: 'Error adding facility',
                    successMessage: ''
                });
                console.error(error);
            }
        }

    };

    const compare = (a: Site, b: Site) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    };

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        setShowLoader(true);
        MaestroSiteService.getAllSites().then((res: Site[]) => {
            setMaestroSites(res);

            ApprovedSiteService.getAllApprovedSites(true).then((res: Site[]) => {
                setApprovedSites(res);

                setShowLoader(false);
            });
        });
    }, []);

    return <div>
        <Loader open={showLoader}>
            <form>

                <div className="container m-2">
                    <div className="row">
                        <div className="col-sm">
                            <label>Add Facility</label>
                            <SiteSelect availableSites={maestroSites} siteSelected={handleAddSite} />
                        </div>
                        <div className="col-sm">

                        </div>
                        <div className="col-sm align-self-end">
                            <div className="custom-file" >
                                <input type="file" className="custom-file-input" id="customFile" onChange={handleFileChange} />
                                <label className="custom-file-label">Upload facility csv file</label>
                            </div>
                        </div>
                    </div>
                </div>

                {state.showErrorDiv &&
                    <div className="alert alert-danger" role="alert">{state.errorMessage}</div>
                }
                {state.showSuccessDiv &&
                    <div className="alert alert-success" role="alert">{state.successMessage}</div>
                }
            </form>

            <div className="shadow-sm">
                <table className="table table-striped table-bordered table-sm">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Address</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {approvedSites.map((site, i) => {
                            return <ApprovedSiteRow key={`approved-site-${i}`} site={site} showCounts={false} deleteSite={() => setRemoveConfirmationDialogTarget(site.siteId)} />;
                        })}
                    </tbody>
                </table>
            </div>
        </Loader>
        <Modal
            show={removeConfirmationDialogTarget !== null}
            onHide={() => setRemoveConfirmationDialogTarget(null)}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Facility</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure that you want to remove the approved facility?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setRemoveConfirmationDialogTarget(null)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleDeleteSite(removeConfirmationDialogTarget)}>
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={addConfirmationDialogTarget !== null}
            onHide={() => setAddConfirmationDialogTarget(null)}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Add Facility</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure that you want to add {addConfirmationDialogTarget?.name} to the list of approved facilities?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setAddConfirmationDialogTarget(null)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => addApprovedSite()}>
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;


};
export default ApprovedSitesControl;