import React, { useState, useEffect } from 'react';
import AddEditOrder from './AddEditOrder';
import OrderTable from './OrderTable';
import Loader from '../Shared/Loader';
import SiteSelect from '../Shared/SiteSelect';
import { Order } from '../../models/Order';
import { defaultSite, Site } from '../../models/Site';
import { SiteSearchOption } from '../../models/SiteSearchOption';
import { User } from '../../models/User';
import OrderService from '../../services/OrderService';
import UserService from '../../services/UserService';
import { OrderStatusType, RoleEnum } from '../../values/Enums';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Confirmation from '../Shared/Confirmation';
import { Button, Modal } from 'react-bootstrap';
import GlobalAuthorize from '../api-authorization/GlobalAuthorize';

interface PropsData {
    currentUser: User | null;
}

interface StateData {
    showErrorDiv: boolean;
    showSuccessDiv: boolean;
    errorMessage: string;
    successMessage: string;
}

const Dashboard: React.FC<PropsData> = (props) => {

    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [orders, setOrders] = useState<Order[]>([]);
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
    const [editOrderId, setEditOrderId] = useState<string | null>(null);
    const [showDeactivated, setShowDeactivated] = useState<boolean>(false);
    const [selectedSite, setSelectedSite] = useState<Site | null>(null);
    const [orderStatus, setOrderStatus] = useState<OrderStatusType>(OrderStatusType.Ordered);

    const [confirmationDialogTarget, setConfirmationDialogTarget] = useState<string | null>(null);

    const [state, setState] = useState<StateData>({
        showErrorDiv: false,
        showSuccessDiv: false,
        errorMessage: '',
        successMessage: ''
    } as StateData);

    //first load
    useEffect(() => {

        let defaultSite = props.currentUser?.sites[0];
        var currentRole = props.currentUser?.role ?? RoleEnum.Site;
        if ((!props.currentUser || !defaultSite?.siteId) && currentRole === RoleEnum.Site) {
            return;
        }

        setShowLoader(true);

        if (selectedSite) {
            OrderService.getSite(selectedSite.siteId, showDeactivated).then((orders) => {
                setOrders(orders);
            }).finally(() => {
                setShowLoader(false);
            });
        }
        else {
            OrderService.getAll(showDeactivated).then((orders) => {
                setOrders(orders);
            }).finally(() => {
                setShowLoader(false);
            });
        }



    }, [props.currentUser, showDeactivated]);

    const reloadOrders = async (siteId: string, showDeactivated: boolean): Promise<void> => {
        //change the target site
        if (siteId) {
            const orders = await OrderService.getSite(siteId, showDeactivated);
            setOrders(orders);
        }
        else {

            const orders = await OrderService.getAll(showDeactivated);
            setOrders(orders);
        }
    };

    const setEditPatientModal = (orderId: string) => {

        setEditOrderId(orderId);

        const order = orders.filter(o => o.id === orderId)[0];
        const lastStatus = order.statusHistory[0].status;
        setOrderStatus(lastStatus);
        setShowAddEdit(true);

    };

    const handleSiteUpdate = async (selectedOption: SiteSearchOption) => {
        setShowLoader(true);
        //get the selected site
        const selectedOptionArray = (selectedOption instanceof Array) ? selectedOption : [selectedOption];
        const filteredOption = selectedOptionArray[0];

        let selectedSiteEntry: Site = defaultSite;

        if (filteredOption?.siteId) {
            selectedSiteEntry = props.currentUser?.sites?.filter(s => s.siteId === filteredOption.siteId)[0] ?? defaultSite;
        }

        setSelectedSite((selectedSiteEntry.siteId === defaultSite.siteId) ? null : selectedSiteEntry );

        await reloadOrders(selectedSiteEntry?.siteId ?? "", showDeactivated);
        setShowLoader(false);

        setState({
            ...state,
            successMessage: '',
            errorMessage: '',
            showSuccessDiv: false,
            showErrorDiv: false
        });
    };

    const deactivateOrder = async (orderId: string) => {
        setShowLoader(true);

        setConfirmationDialogTarget(null);
        await OrderService.deactivate(orderId);

        // var orders = await OrderService.getAll(showDeactivated);
        // setOrders(orders);

        await reloadOrders(selectedSite?.siteId ?? "", showDeactivated);

        setState({
            ...state,
            successMessage: 'Deactivated order',
            errorMessage: '',
            showSuccessDiv: true,
            showErrorDiv: false
        });
        setShowLoader(false);
    };

    const openAddOrderModal = () => {
        setShowAddEdit(true);

        setState({
            ...state,
            successMessage: '',
            errorMessage: '',
            showSuccessDiv: false,
            showErrorDiv: false
        });
    };


    const handleModalClose = async (success: boolean | null, message: string | null): Promise<void> => {

        if (success === true) {
            setShowLoader(true);
            await reloadOrders(selectedSite?.siteId ?? "", showDeactivated);

            setState({
                ...state,
                successMessage: message ?? '',
                errorMessage: '',
                showSuccessDiv: true,
                showErrorDiv: false
            });
            setShowLoader(false);
        }
        else if (success === false) {
            setState({
                ...state,
                successMessage: '',
                errorMessage: message ?? '',
                showSuccessDiv: false,
                showErrorDiv: true
            });
        }
        else {
            setState({
                ...state,
                errorMessage: '',
                showSuccessDiv: false,
                showErrorDiv: false
            });
        }
        setShowAddEdit(false);
        setOrderStatus(OrderStatusType.Ordered);
        setEditOrderId(null);
    };

    if (props.currentUser === null || !props.currentUser || !props.currentUser.fullName) {
        return <div></div>;
    }

    return (
        <div>

            <Loader open={(showLoader)}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            Facility <SiteSelect availableSites={props.currentUser?.sites ?? []} siteSelected={handleSiteUpdate} selectedSite={selectedSite} clearable={true} />

                        </div>
                        <div className="col-sm text-left align-self-end">
                            <BootstrapSwitchButton
                                checked={showDeactivated}
                                onlabel='Show Deactivated'
                                offlabel='Hide Deactivated'
                                width={170}
                                onChange={(checked: boolean) => {
                                    setShowDeactivated(checked);
                                    setState({
                                        ...state,
                                        successMessage: '',
                                        errorMessage: '',
                                        showSuccessDiv: false,
                                        showErrorDiv: false
                                    });
                                }}
                            />
                        </div>
                        <div className="col-sm text-right align-self-end">
                            <button type="button" className="btn btn-primary font-weight-bold" onClick={openAddOrderModal}>Add Order</button>
                        </div>
                    </div>
                </div>

                {state.showErrorDiv &&
                    <div className="alert alert-danger" role="alert">{state.errorMessage}</div>
                }
                {state.showSuccessDiv &&
                    <div className="alert alert-success" role="alert">{state.successMessage}</div>
                }

                {orders && orders.length > 0 &&
                    <OrderTable currentUser={props.currentUser} orders={orders} confirmDeactivate={(id: string) => setConfirmationDialogTarget(id)} editOrder={(id: string) => setEditPatientModal(id)} />
                }
                {
                    orders && orders.length == 0 &&
                    <div className="alert alert-dark" style={{ marginTop: 8 }} role="alert">
                        No orders found
                    </div>
                }

                {showAddEdit &&
                    <AddEditOrder showModal={showAddEdit} orderId={editOrderId} orderStatus={orderStatus} onClose={handleModalClose} userSites={props.currentUser?.sites ?? []} currentUser={props.currentUser}></AddEditOrder>
                }

            </Loader>
            <Modal
                show={confirmationDialogTarget !== null}
                onHide={() => setConfirmationDialogTarget(null)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Deactivate Device</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure that you want to initiate a device deactivation?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setConfirmationDialogTarget(null)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => deactivateOrder(confirmationDialogTarget!)}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};
export default Dashboard;