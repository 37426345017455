import Logger from './Logger';

const className = 'Timer';

class Timer {
    private readonly intervals: Map<string, any>;
    private readonly timeouts: Map<string, any>;

    public constructor() {
        this.intervals = new Map<string, any>();
        this.timeouts = new Map<string, any>();
    }

    public clearAll(): void {
        this.intervals.forEach(handle => clearInterval(handle));
        this.timeouts.forEach(handle => clearTimeout(handle));
    }

    public clearInterval(name: string): void {
        const handle = this.intervals.get(name);
        clearInterval(handle);
        this.intervals.delete(name);
    }

    public clearTimeout(name: string): void {
        const handle = this.timeouts.get(name);
        clearTimeout(handle);
        this.timeouts.delete(name);
    }

    public setInterval(name: string, callback: () => void, interval: number): void {
        const handle = setInterval(() => {
            try {
                callback();
            } catch (error) {
                Logger.error(`${className} - Interval error`, error);
            }
        }, interval);

        this.timeouts.set(name, handle);
    }

    public setTimeout(name: string, callback: () => void, timeout: number): void {
        const handle = setTimeout(() => {
            try {
                callback();
            } catch (error) {
                Logger.error(`${className} - Timeout error`, error);
            }
        }, timeout);

        this.timeouts.set(name, handle);
    }
}

export default new Timer();
