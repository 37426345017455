export interface Patient {
    patientId: string;
    customerPatientIdentifier: string;    
    firstName: string;
    lastName: string;

    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;

    phoneNumber: string;
    email: string;
}

export const defaultPatient: Patient = {
    patientId: '',
    customerPatientIdentifier: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    email: ''
}